<template>
    <div id="usermanage">
        <div class="infoMain">
            <alert-info
                :info="{ zysx: '加“*”是必填项，请按规定填写！' }"
            ></alert-info>
            <div class="userinfo scrollbar">
                <el-form
                    ref="formTable"
                    :model="formTable"
                    :rules="rules"
                    label-width="200px"
                    class="demo-ruleForm"
                >
                    <p class="header">基本信息</p>
                    <el-form-item label="用户" prop="Userid" v-if="!addState">
                        <div class="flex">
                            <el-select
                                v-model="formTable.Userid"
                                placeholder="请选择"
                                clearable=""
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item, index) in userList"
                                    :key="index"
                                    :label="item.REALNAME"
                                    :value="item.ID"
                                ></el-option>
                            </el-select>
                            <div
                                class="addBtn pointer"
                                @click="addState = !addState"
                            >
                                新增
                            </div>
                        </div>
                    </el-form-item>
                    <div v-if="addState">
                        <el-form-item label="用户名" prop="NAME">
                            <div class="flex">
                                <el-input
                                    v-model="formTable.NAME"
                                    style="width: 200px"
                                ></el-input>

                                <div
                                    class="addBtn pointer"
                                    @click="addState = !addState"
                                >
                                    新增
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="姓名" prop="REALNAME">
                            <div class="flex">
                                <el-input
                                    v-model="formTable.REALNAME"
                                    style="width: 200px"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-radio
                                v-model="formTable.SEX"
                                class="radio"
                                :label="1"
                                >男</el-radio
                            >
                            <el-radio
                                v-model="formTable.SEX"
                                class="radio"
                                :label="2"
                                >女</el-radio
                            >
                        </el-form-item>
                        <el-form-item label="密码" prop="PASSWORD">
                            <div class="flex">
                                <el-input
                                    v-model="formTable.PASSWORD"
                                    type="password"
                                    style="width: 200px"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="PASSWORDOK">
                            <div class="flex">
                                <el-input
                                    v-model="formTable.PASSWORDOK"
                                    type="password"
                                    style="width: 200px"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="MOBILE">
                            <el-input
                                v-model="formTable.MOBILE"
                                onkeydown="if(event.keyCode==32) return false"
                            ></el-input>
                        </el-form-item>
                    </div>

                    <el-form-item label="工作概况" prop="Gzgk">
                        <el-input
                            v-model="formTable.Gzgk"
                            type="textarea"
                            :autosize="{ minRows: 3 }"
                            :maxlength="200"
                            onkeydown="if(event.keyCode==32) return false"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="工作起始时间" prop="Gzqssj">
                        <el-date-picker
                            v-model="formTable.Gzqssj"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择工作起始时间"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="工作结束时间" prop="Gzjssj">
                        <el-date-picker
                            v-model="formTable.Gzjssj"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择工作结束时间"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注" prop="Bz">
                        <el-input
                            v-model="formTable.Bz"
                            type="textarea"
                            :autosize="{ minRows: 3 }"
                            :maxlength="200"
                            onkeydown="if(event.keyCode==32) return false"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="App权限" prop="">
                        <el-switch
                            v-for="(item, index) in appLimitList"
                            :key="index"
                            v-model="item.state"
                            :active-value="true"
                            :inactive-value="false"
                            style="margin-right: 30px"
                            :active-text="item.name"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item class="buttonItem">
                        <sn-button
                            size="small"
                            :snButton="saveBtn"
                            @handleChange="save"
                            >保存</sn-button
                        >
                        <router-link :to="{ name: 'forestPeople' }">
                            <sn-button :snButton="cancleBtn" size="small"
                                >取消</sn-button
                            >
                        </router-link>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import alertInfo from "@comp/alert_info";
import { mapActions } from "vuex";
export default {
    name: "",
    components: {
        alertInfo,
    },
    data() {
        return {
            addState: false, //TRUE 新增 false 修改
            userList: [],
            rules: {
                REALNAME: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                NAME: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error("请输入用户名"));
                            } else {
                                this.checkname(value).then((val) => {
                                    if (!val) {
                                        callback(new Error("用户名已存在"));
                                    } else {
                                        callback();
                                    }
                                });
                            }
                        },
                        trigger: "blur",
                    },
                ],
                PASSWORD: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                PASSWORDOK: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error("请再次输入密码"));
                            } else if (value !== this.formTable.PASSWORD) {
                                callback(new Error("两次输入密码不一致!"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                MOBILE: [
                    {
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur",
                    },
                ],
                Userid: [
                    {
                        required: true,
                        message: "请选择用户",
                        trigger: "blur",
                    },
                ],
                Gzqssj: [
                    {
                        required: true,
                        message: "请选择工作起始时间",
                        trigger: "blur",
                    },
                ],
            },
            saveBtn: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "save",
                        name: "保存",
                        round: true,
                        backColor: "#28ccd9",
                        disabled: false,
                    },
                ],
            },
            cancleBtn: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "",
                        name: "取消",
                        round: true,
                        backColor: "#ff9066",
                    },
                ],
            },
            strId: null,
            newdoc: "xinjian",
            nameold: "",
            formTable: {
                Id: "",
                Userid: "",
                Gzgk: "",
                Gzqssj: "",
                Gzjssj: "",
                Bz: "",
                REALNAME: "", //姓名
                NAME: "", //用户名
                SEX: 1, //用户名
                PASSWORD: "",
                PASSWORDOK: "",
                MOBILE: "",
            },
            isEdit: true,
            // app权限列表
            appLimitList: [
                {
                    id: "",
                    name: "日常巡查",
                    state: false,
                },
                {
                    id: "",
                    name: "保护工程",
                    state: false,
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.getAppList();
        this.getUsersList();
        let obj = {};
        switch (this.$route.query.actType) {
            case "0":
                this.isEdit = true;
                break;
            case "1":
                this.isEdit = false;
                obj = this.$route.query;
                this.formTable.Id = obj.ID;
                this.formTable.Userid = obj.USERID;
                this.formTable.Gzgk = obj.GZGK;
                this.formTable.Gzqssj = obj.GZQSSJ;
                this.formTable.Gzjssj = obj.GZJSSJ;
                this.formTable.Bz = obj.BZ;

                break;
            case "2":
                this.isEdit = true;
                obj = this.$route.query;
                this.formTable.Id = obj.ID;
                this.formTable.Userid = "";
                this.formTable.Gzgk = obj.GZGK;
                this.formTable.Gzqssj = "";
                this.formTable.Gzjssj = "";
                this.formTable.Bz = obj.BZ;

                break;
        }
        if (this.$route.query.actType != "0") {
            this.getUserInfo();
        }
    },
    methods: {
        ...mapActions([
            "getUserList",
            "SaveSlhlyInfo",
            "checkUserName",
            "getMobleFunList",
            "getUserData",
        ]),
        // 获得用户详细信息
        async getUserInfo() {
            let result = await this.getUserData({
                userid: this.$route.query.USERID,
            });
            if (result.FUNCID) {
                let list = result.FUNCID.split(",");
                this.appLimitList.forEach((item, index) => {
                    if (list.includes(item.id)) {
                        this.appLimitList[index].state = true;
                    }
                });
            }
        },
        // 获取用户列表
        async getUsersList() {
            let result = await this.getUserList({
                depID: "23147581-40b3-4ff7-b7dc-2fff54710dba",
            });
            this.userList = result.Data;
        },
        // 获取权限列表
        async getAppList() {
            let result = await this.getMobleFunList({});
            result.map((item) => {
                if (item.NAME == "日常巡查") {
                    this.appLimitList[0].id = item.ID;
                } else if (item.NAME == "保护工程") {
                    this.appLimitList[1].id = item.ID;
                }
            });
        },
        save() {
            this.$refs.formTable.validate((valid) => {
                if (valid) {
                    this.savePeople();
                    // this.$confirm("此操作将添加到数据库, 是否继续?", "提示", {
                    //     confirmButtonText: "确定",
                    //     cancelButtonText: "取消",
                    //     type: "warning",
                    // })
                    //     .then(() => {
                    //         // this.saveBtn.buttonData[0].disabled = true;
                    //     })
                    //     .catch(() => {
                    //         this.common.showMsg("已取消保存", "info");
                    //     });
                } else {
                    return false;
                }
            });
        },
        async savePeople() {
            let funids = [];
            this.appLimitList.forEach((item, index) => {
                if (item.state) {
                    funids.push(item.id);
                }
            });

            let isreplace = "";
            if (
                this.$route.query.actType == "0" ||
                this.$route.query.actType == "1"
            ) {
                isreplace = 0;
            } else {
                isreplace = 1;
            }
            //新增
            let userinfo = {
                NAME: this.formTable.NAME, //姓名
                PASSWORD: this.formTable.PASSWORD, //密码
                REALNAME: this.formTable.REALNAME, //真实姓名
                MOBILE: this.formTable.MOBILE, //手机号
                SEX: this.formTable.SEX, //性别
                USERTYPE: 1, //用户类型（写死，都是web用户）
            };

            let res = await this.SaveSlhlyInfo({
                info: JSON.stringify(this.formTable),
                isreplace: isreplace, //0 不替换 1 替换
                userinfo: this.addState ? JSON.stringify(userinfo) : "",
                funids: funids.join(","),
            });
            if (res.ISSUCCESS) {
                this.common.showMsg("保存成功", "success");
                this.$router.push({
                    name: "forestPeople",
                });
            } else {
                this.common.showMsg("保存失败", "error");
            }
        },
        async checkname(name) {
            let res = await this.checkUserName({
                name: name,
            });
            return res;
        },
    },
};
</script>
<style lang="scss" scoped>
.header {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #606266;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.addBtn {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-left: 20px;
    border-radius: 5px;
    margin-top: 4px;
    text-align: center;
}
</style>
